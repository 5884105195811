import "@fontsource/nunito/400.css";
import "@fontsource/nunito/600.css";
import "./index.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { Amplify } from "aws-amplify";
import { Provider as BusProvider } from "react-bus";
import CssBaseline from "@mui/material/CssBaseline";
import { IntercomProvider } from "react-use-intercom";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga";
import Routes from "./services/routing";
import { ThemeProvider } from "@mui/material/styles";
import { hotjar } from "react-hotjar";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 15,
    },
  },
});

Amplify.configure({
  Auth: {
    Cognito: {
      region: process.env.REACT_APP_AMPLIFY_REGION,
      userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID,
    },
  },
});

hotjar.initialize(
  process.env.REACT_APP_HOTJAR_SITE_ID,
  process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
);

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  ReactGA.initialize("G-YE1X4TJ846");
}

root.render(
  <React.StrictMode>
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BusProvider>
            <Routes />
          </BusProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </IntercomProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
