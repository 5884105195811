import React, { useState, useCallback, forwardRef } from "react";
import { atom, useAtom } from "jotai";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import {
  MenuBook as MenuBookIcon,
  Email as EmailIcon,
  Chat as ChatIcon,
  Event as EventIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useIntercom } from "react-use-intercom";
import { onboardingState } from "../../../services/store";

export const helpDialogOpenAtom = atom(false);

export const HelpDialog = forwardRef(({ ...props }, ref) => {
  const [open, setOpen] = useAtom(helpDialogOpenAtom);
  const [onboarding] = useAtom(onboardingState);
  const { boot } = useIntercom();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleClose = () => {
    if (!onboarding.isActive || onboarding.currentStep !== 5) {
      setOpen(false);
    }
  };

  const handleChatWithRhetoric = () => {
    boot();
    setTimeout(() => {
      window.Intercom("show");
    }, 100);
  };

  const handleScheduleTraining = () => {
    window.open("https://calendly.com/meetwithrhetoric", "_blank");
  };

  const handleCopyEmail = useCallback(() => {
    navigator.clipboard.writeText("onboarding@userhetoric.com").then(() => {
      setSnackbarOpen(true);
    });
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog
        ref={ref}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            pointerEvents: onboarding.currentStep === 5 ? "none" : "auto",
          },
        }}
      >
        <DialogTitle variant="h4" sx={{ marginTop: 2, paddingRight: 6 }}>
          How can we help you?
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              pointerEvents: onboarding.currentStep === 5 ? "none" : "auto",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem
              button
              component="a"
              href="https://doc.clickup.com/45029316/p/h/1ay5y4-2274/21c3ee4c251de7e"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItemIcon>
                <MenuBookIcon
                  sx={{ color: (theme) => theme.palette.primary.main }}
                />
              </ListItemIcon>
              <ListItemText
                primary="User Documentation"
                secondary="Access our comprehensive user guide"
              />
            </ListItem>

            <ListItem button onClick={handleChatWithRhetoric}>
              <ListItemIcon>
                <ChatIcon
                  sx={{ color: (theme) => theme.palette.primary.main }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Chat With Rhetoric"
                secondary="Get instant help via chat"
              />
            </ListItem>
            <ListItem button onClick={handleScheduleTraining}>
              <ListItemIcon>
                <EventIcon
                  sx={{ color: (theme) => theme.palette.primary.main }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Schedule Training"
                secondary="Book a training session with our team"
              />
            </ListItem>
            <ListItem button onClick={handleCopyEmail}>
              <ListItemIcon>
                <EmailIcon
                  sx={{ color: (theme) => theme.palette.primary.main }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Email Support"
                secondary={
                  <>
                    Contact us at{" "}
                    <Typography
                      component="span"
                      variant="inherit"
                      sx={{ fontWeight: "bold" }}
                    >
                      onboarding@userhetoric.com
                    </Typography>
                    <Typography
                      variant="inherit"
                      component="span"
                      display="block"
                      sx={{ mt: 0.5, fontSize: "0.9rem" }}
                    >
                      (Click here to copy)
                    </Typography>
                  </>
                }
              />
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          message="Email copied to clipboard"
          sx={{
            justifyContent: "center",
            "& .MuiSnackbarContent-message": {
              flex: "none",
            },
          }}
        />
      </Snackbar>
    </>
  );
});

HelpDialog.displayName = "HelpDialog";
