import { Box, CircularProgress, Typography, Paper, Link } from "@mui/material";
import React, { Suspense, useEffect } from "react";
import { CiceroEmpty } from "./CiceroEmpty";
import { CiceroItems } from "./CiceroItems";
import { Default } from "../../layouts";
import { Error } from "../../views/Error";
import { useLocation } from "react-router-dom";
import { useCompletedAssignments } from "../../../services/queries";

const CiceroView = () => {
  const location = useLocation();
  const {
    data: completedAssignments,
    isLoading,
    error,
    refetch,
  } = useCompletedAssignments();

  useEffect(() => {
    if (location.pathname === "/cicero") {
      refetch();
    }
  }, [location.pathname, refetch]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size="3rem" />
      </Box>
    );
  }

  if (error) {
    return <Error message={error.message} />;
  }

  if (completedAssignments === "user not authorized to use Cicero") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="70vh"
      >
        <Paper elevation={3} sx={{ padding: 4, maxWidth: 600 }}>
          <Typography variant="h3" marginBottom={1}>
            Access Denied
          </Typography>
          <Typography variant="body1">
            You do not have permission to access Cicero. To request an upgrade
            for your Rhetoric profile to include Cicero, or if you believe this
            message was sent in error, please contact us at{" "}
            <Link href="mailto:support@userhetoric.com">
              support@userhetoric.com
            </Link>{" "}
            for assistance.
          </Typography>
        </Paper>
      </Box>
    );
  }

  const assignments = Array.isArray(completedAssignments)
    ? completedAssignments
    : [];

  return (
    <Box>
      {assignments.length > 0 ? (
        <CiceroItems assignments={assignments} />
      ) : (
        <CiceroEmpty />
      )}
    </Box>
  );
};

const LoadingFallback = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
  >
    <CircularProgress size="3rem" />
  </Box>
);

export const Cicero = () => (
  <Default>
    <Error>
      <Suspense fallback={<LoadingFallback />}>
        <CiceroView />
      </Suspense>
    </Error>
  </Default>
);

export default Cicero;
