import * as api from "../../../services/api";

import {
  Button,
  Container,
  Typography,
  Link,
  Box,
  Divider,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReplayIcon from "@mui/icons-material/Replay";

import { ErrorBoundary } from "react-error-boundary";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { useRouteError } from "react-router-dom";
import { Default } from "../../layouts/Default/Default";

const reportErrorToBackend = async (error) => {
  try {
    const payload = {
      level: "ERROR",
      message: error.message,
      url: window.location.href,
      stack: error.stack,
      componentStack: error.componentStack,
      name: error.name,
      code: error.code,
    };

    await api.post("/audit", {
      body: JSON.stringify(payload),
    });
  } catch (reportError) {
    console.error("Failed to report error to backend:", reportError);
  }
};

const ErrorContent = ({ error, resetError }) => (
  <Container maxWidth="md" sx={{ mt: 8, mb: 8 }}>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <ErrorOutlineIcon color="error" sx={{ fontSize: 64, mb: 2 }} />
      <Typography variant="h4" gutterBottom>
        Oops! Something went wrong.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, maxWidth: "600px" }}>
        We apologize for the inconvenience. Please try reloading the data or
        contact our support team if the issue persists.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ReplayIcon />}
        onClick={() => {
          reportErrorToBackend(error);
          if (resetError) resetError();
        }}
        sx={{ mb: 4 }}
      >
        Reload Data
      </Button>
      <Divider sx={{ width: "100%", mb: 4 }} />
      <Typography variant="body2" color="text.secondary">
        If you continue to experience issues, please contact us at{" "}
        <Link href="mailto:support@userhetoric.com" color="primary">
          support@userhetoric.com
        </Link>
      </Typography>
    </Box>
  </Container>
);

const ErrorFallback = ({ error, resetErrorBoundary }) => (
  <ErrorContent error={error} resetError={resetErrorBoundary} />
);

const RouteErrorView = ({ error }) => (
  <Default>
    <ErrorContent error={error} />
  </Default>
);

export function Error({ children }) {
  const routeError = useRouteError();

  if (routeError) {
    reportErrorToBackend(routeError);
    return <RouteErrorView error={routeError} />;
  }

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          onError={reportErrorToBackend}
          FallbackComponent={ErrorFallback}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
