import * as api from "../api";
import { useQuery } from "@tanstack/react-query";

const getConfig = async (config) => {
  const response = await api.get(`/config/${config}`);
  return response.config_value;
};

const useConfig = (paramName) => {
  return useQuery({
    queryKey: [paramName],
    queryFn: () => getConfig(paramName),
  });
};

export const useIsCiceroEnabled = () => {
  const { data } = useConfig("enable_cicero");
  return data === 1;
};

export const useLowSampleThreshold = () => {
  const { data } = useConfig("low_sample_threshold");
  return data;
};

export const useMaintenanceCheckInterval = () => {
  const { data } = useConfig("maintenance_check_interval");
  return data || 5;
};

export const useMaintenanceMaxAttempts = () => {
  const { data } = useConfig("maintenance_max_attempts");
  return data || 10;
};
