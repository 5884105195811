import * as api from "../../../services/api";

import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { atom, useAtom } from "jotai";

import { DeBriefEmpty } from "./DeBriefEmpty";
import { DeBriefItems } from "./DeBriefItems";
import { Default } from "../../layouts";
import { Error } from "../../views/Error";
import Grid from "@mui/material/Unstable_Grid2";
import { useInfiniteQuery } from "@tanstack/react-query";

const selectedAtom = atom(-1);

const DeBriefView = () => {
  return (
    <Default>
      <Error>
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress size="3rem" />
            </Box>
          }
        >
          <Fetch />
        </Suspense>
      </Error>
    </Default>
  );
};

function Fetch() {
  const [selected, setSelected] = useAtom(selectedAtom);
  const [page, setPage] = useState(1);

  const handleSelect = useCallback(
    (value) => {
      setSelected(value);
    },
    [setSelected]
  );

  const {
    isLoading,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: ["briefs"],
    queryFn: async ({ pageParam = 1 }) =>
      await api.get(`/v3/briefs?page=${pageParam}`),
    getNextPageParam: (lastPage) => {
      return lastPage.num_pages === page ? undefined : page + 1;
    },
    throwOnError: true,
  });

  const handleFetchMore = useCallback(() => {
    const next = page + 1;
    setPage(next);
    fetchNextPage();
  }, [fetchNextPage, page]);

  useEffect(() => {
    if (isRefetching) {
      setPage(1);
    }
  }, [isRefetching]);

  const items = useMemo(() => {
    return data?.pages?.map((data) => data.briefs).flat() || [];
  }, [data]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size="3rem" />
      </Box>
    );
  }

  return (
    <>
      {items.length === 0 && (
        <Box padding={4}>
          <Grid container spacing={2}>
            <DeBriefEmpty />
          </Grid>
        </Box>
      )}
      {items && items?.length !== 0 && (
        <DeBriefItems
          selected={selected}
          setSelected={handleSelect}
          items={items}
        />
      )}

      {(isFetchingNextPage || hasNextPage) && items && items?.length !== 0 && (
        <Stack sx={{ py: 4 }}>
          <Button onClick={handleFetchMore} variant="contained">
            {isFetchingNextPage ? (
              <CircularProgress color="inherit" size="3rem" />
            ) : (
              "Load More"
            )}
          </Button>
        </Stack>
      )}
    </>
  );
}

export const DeBrief = () => <DeBriefView />;
