import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useEffect, useState } from "react";

const StepContent = ({ step, highlightRef }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    "(max-height: 1000px), (max-width: 1800px)"
  );
  const [highlightBox, setHighlightBox] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const updateHighlightPosition = () => {
      if (highlightRef?.current) {
        const rect = highlightRef.current.getBoundingClientRect();
        setHighlightBox({
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height,
        });
      }
    };

    updateHighlightPosition();
    window.addEventListener("resize", updateHighlightPosition);

    return () => window.removeEventListener("resize", updateHighlightPosition);
  }, [highlightRef]);

  const commonHighlightStyles = {
    position: "fixed",
    top: highlightBox.top - 16,
    left: highlightBox.left - 16,
    width: highlightBox.width + 32,
    height: highlightBox.height + 32,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 2,
    boxShadow: `0 0 15px 1px ${theme.palette.primary.main}`,
    zIndex: 1301,
    transition: "all 0.3s ease",
    backgroundColor: "transparent",
    clipPath: "inset(-20px -20px -20px -20px)",
    pointerEvents: "none",
    backdropFilter: "brightness(2)",
  };

  const commonArrowStyles = {
    color: theme.palette.primary.main,
    fontSize: isSmallScreen ? 40 : 80,
    filter: `drop-shadow(0 0 8px ${theme.palette.text.white})`,
    transition: "all 0.3s ease",
  };

  const commonPaperStyles = {
    p: isSmallScreen ? 3 : 5,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    maxWidth: isSmallScreen ? 400 : 500,
    minHeight: isSmallScreen ? 200 : 280,
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const smallScreenTypographyStyles = {
    h4: {
      fontSize: isSmallScreen ? "1.3rem !important" : "2.125rem",
      marginBottom: isSmallScreen ? "0.5rem !important" : "2rem",
    },
    body1: {
      fontSize: isSmallScreen ? "0.9rem !important" : "1rem",
      lineHeight: isSmallScreen ? 1.4 : 1.5,
      marginBottom: isSmallScreen ? "0.5rem" : "1rem",
    },
  };

  if (step === 1) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <Box
          sx={{
            position: "fixed",
            top:
              highlightBox.top +
              highlightBox.height +
              (isSmallScreen ? 20 : 40),
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1302,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: isSmallScreen ? 1 : 2,
            transition: "all 0.3s ease",
          }}
        >
          <ArrowUpwardIcon sx={commonArrowStyles} />
          <Paper sx={commonPaperStyles}>
            <Typography
              variant="h4"
              align="center"
              sx={{
                ...smallScreenTypographyStyles.h4,
                fontWeight: 500,
              }}
            >
              Welcome to Rhetoric
            </Typography>
            <Typography
              variant="body1"
              sx={{
                ...smallScreenTypographyStyles.body1,
                mb: isSmallScreen ? 1 : 2,
              }}
            >
              Your dashboard displays our suite of legal analytics tools. Each
              tool is designed to enhance different aspects of your legal
              practice.
            </Typography>
            <Typography variant="body1" sx={smallScreenTypographyStyles.body1}>
              Select any tool using the "View" button to explore its
              capabilities.
            </Typography>
          </Paper>
        </Box>
      </>
    );
  }

  if (step === 2) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <Box
          sx={{
            position: "fixed",
            top: highlightBox.top + highlightBox.height + 170,
            left: "13%",
            zIndex: 1302,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: isSmallScreen ? 1 : 3,
            transition: "all 0.3s ease",
          }}
        >
          <Box
            sx={{
              display: "flex",
              transform: "rotate(315deg)",
              marginTop: -10,
            }}
          >
            <ArrowUpwardIcon sx={commonArrowStyles} />
          </Box>

          <Paper sx={commonPaperStyles}>
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              sx={smallScreenTypographyStyles.h4}
            >
              Navigation Menu
            </Typography>
            <Typography variant="body1" sx={smallScreenTypographyStyles.body1}>
              The sidebar menu provides quick access to all Rhetoric tools from
              anywhere in the platform. Use these shortcuts to seamlessly switch
              between different analytics features.
            </Typography>
          </Paper>
        </Box>
      </>
    );
  }

  if (step === 3) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <Box
          sx={{
            position: "fixed",
            top: highlightBox.top + highlightBox.height - 440,
            left: "20%",
            zIndex: 1302,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: isSmallScreen ? 1 : 3,
            transition: "all 0.3s ease",
          }}
        >
          <Box
            sx={{
              display: "flex",
              transform: "rotate(225deg)",
              marginTop: 33,
            }}
          >
            <ArrowUpwardIcon sx={commonArrowStyles} />
          </Box>
          <Paper sx={commonPaperStyles}>
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              sx={smallScreenTypographyStyles.h4}
            >
              Video Tutorials
            </Typography>
            <Typography variant="body1" sx={smallScreenTypographyStyles.body1}>
              Access context-specific video guides for each section of Rhetoric.
              This button is available throughout the entire platform, with each
              section having its own tailored video guide. Whether you're in
              Doctrina, DeBrief, or Judges, you'll find specific tutorial
              content designed for that feature.
            </Typography>
          </Paper>
        </Box>
      </>
    );
  }

  if (step === 4) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <Box
          sx={{
            position: "fixed",
            top: highlightBox.top + highlightBox.height - 430,
            left: "20%",
            zIndex: 1302,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: isSmallScreen ? 1 : 3,
            transition: "all 0.3s ease",
          }}
        >
          <Box
            sx={{
              display: "flex",
              transform: "rotate(225deg)",
              marginTop: 33,
            }}
          >
            <ArrowUpwardIcon sx={commonArrowStyles} />
          </Box>
          <Paper sx={commonPaperStyles}>
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              sx={smallScreenTypographyStyles.h4}
            >
              Help & Support
            </Typography>
            <Typography variant="body1" sx={smallScreenTypographyStyles.body1}>
              Access our comprehensive help center with a single click. Find
              answers to common questions and get assistance whenever you need
              it.
            </Typography>
          </Paper>
        </Box>
      </>
    );
  }

  if (step === 5) {
    return (
      <>
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "475px",
            height: "475px",
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: 2,
            boxShadow: `0 0 15px 1px ${theme.palette.primary.main}`,
            zIndex: 1301,
            backgroundColor: "transparent",
            pointerEvents: "none",
            backdropFilter: "brightness(1)",
          }}
        />

        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "18%",
            transform: "translate(-50%, -50%)",
            zIndex: 1302,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: isSmallScreen ? 2 : 5,
            transition: "all 0.3s ease",
          }}
        >
          <Paper sx={{ ...commonPaperStyles }}>
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              sx={smallScreenTypographyStyles.h4}
            >
              Support Resources
            </Typography>
            <Typography variant="body1" sx={smallScreenTypographyStyles.body1}>
              Our help center offers multiple support channels: browse
              documentation, chat with our team, schedule training sessions, or
              reach out via email. Choose the option that best suits your needs.
            </Typography>
          </Paper>
          {!isSmallScreen && (
            <Box
              sx={{
                transform: "rotate(90deg)",
                marginTop: 0,
              }}
            >
              <ArrowUpwardIcon sx={commonArrowStyles} />
            </Box>
          )}
        </Box>
      </>
    );
  }

  return null;
};

export const OnboardingTour = ({
  onClose,
  currentStep = 1,
  totalSteps = 5,
  onNext,
  onBack,
  highlightRef,
}) => {
  const theme = useTheme();

  return (
    <>
      {currentStep < 5 && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1300,
          }}
        />
      )}

      <Box
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          zIndex: 1302,
          backgroundColor:
            currentStep === 1
              ? "rgba(128, 128, 128, 0.1)"
              : "rgba(255, 255, 255, 0.1)",
          borderRadius: "50%",
          padding: "4px",
          transition: "all 0.2s ease",
          "&:hover": {
            backgroundColor:
              currentStep === 1
                ? "rgba(128, 128, 128, 0.2)"
                : "rgba(255, 255, 255, 0.2)",
            transform: "scale(1.1)",
          },
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            color:
              currentStep === 1
                ? "grey.500"
                : currentStep === 5
                ? "grey.500"
                : "white",
            border: "2px solid",
            borderColor:
              currentStep === 1
                ? "grey.500"
                : currentStep === 5
                ? "grey.500"
                : "white",
            padding: 1,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          aria-label="close onboarding"
        >
          <CloseIcon sx={{ fontSize: 28 }} />
        </IconButton>
      </Box>

      <StepContent step={currentStep} highlightRef={highlightRef} />

      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          p: 2,
          borderRadius: "4px 4px 0 0",
          backgroundColor: "background.paper",
          zIndex: 1302,
          minWidth: "400px",
          maxWidth: "600px",
          width: "40%",
        }}
        elevation={3}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Button
            variant="outlined"
            disabled={currentStep === 1}
            onClick={onBack}
          >
            Back
          </Button>

          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Step {currentStep}/{totalSteps}
          </Typography>

          <Button variant="contained" onClick={onNext}>
            {currentStep === totalSteps ? "Finish" : "Next"}
          </Button>
        </Stack>
      </Paper>
    </>
  );
};
