import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Alert,
} from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import { onboardingState } from "../../../services/store";
import { Default } from "../../layouts";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { ProductCard, OnboardingTour, HelpDialog } from "../../components";
import { useAuth } from "../../../services/auth";
import { useIsCiceroEnabled } from "../../../services/hooks";
import { useRef, useEffect } from "react";
import { helpDialogOpenAtom } from "../../components/HelpDialog/HelpDialog";

export const Home = () => {
  const theme = useTheme();
  const { handleLogout } = useAuth();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const isCiceroEnabled = useIsCiceroEnabled();
  const [onboarding, setOnboarding] = useAtom(onboardingState);
  const productsRef = useRef(null);
  const navigationRef = useRef(null);
  const videoButtonRef = useRef(null);
  const helpButtonRef = useRef(null);
  const setHelpDialogOpen = useSetAtom(helpDialogOpenAtom);
  const helpDialogRef = useRef(null);

  useEffect(() => {
    if (onboarding.currentStep === 5) {
      setHelpDialogOpen(true);
    } else {
      setHelpDialogOpen(false);
    }
  }, [onboarding.currentStep, setHelpDialogOpen]);

  const handleCloseOnboarding = () => {
    setOnboarding({
      isActive: false,
      currentStep: 1,
    });
  };

  const isUnsupportedBrowser = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isChrome = /chrome/.test(userAgent) && !/edg/.test(userAgent);
    const isSafari = /safari/.test(userAgent) && !/chrome/.test(userAgent);
    const isEdge = /edg/.test(userAgent);

    return !(isChrome || isSafari || isEdge);
  };

  const handleNextStep = () => {
    if (onboarding.currentStep === 5) {
      handleCloseOnboarding();
    } else {
      setOnboarding((prev) => ({
        ...prev,
        currentStep: prev.currentStep + 1,
      }));
    }
  };

  const handleBackStep = () => {
    setOnboarding((prev) => ({
      ...prev,
      currentStep: prev.currentStep - 1,
    }));
  };

  const handleStartOnboarding = () => {
    setOnboarding({
      isActive: true,
      currentStep: 1,
    });
  };

  return (
    <Default
      navigationRef={navigationRef}
      videoButtonRef={videoButtonRef}
      helpButtonRef={helpButtonRef}
    >
      {onboarding.isActive && (
        <OnboardingTour
          onClose={handleCloseOnboarding}
          currentStep={onboarding.currentStep}
          totalSteps={5}
          onNext={handleNextStep}
          onBack={handleBackStep}
          highlightRef={
            onboarding.currentStep === 1
              ? productsRef
              : onboarding.currentStep === 2
              ? navigationRef
              : onboarding.currentStep === 3
              ? videoButtonRef
              : onboarding.currentStep === 4
              ? helpButtonRef
              : helpDialogRef
          }
        />
      )}
      <HelpDialog ref={helpDialogRef} />
      <Box
        padding={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <Grid
          ref={productsRef}
          alignItems="stretch"
          container
          rowSpacing={3}
          columnSpacing={5}
        >
          <Grid xs={12}>
            <Typography variant="h1">Your Products</Typography>
          </Grid>
          <Grid xs={12} lg={6}>
            <ProductCard
              heading="Doctrina"
              content="Doctrina helps you get a holistic view of courts, their composition, and their language preferences."
              action={
                <Button
                  component={Link}
                  to="/doctrina"
                  size="small"
                  color="secondary"
                  variant="contained"
                >
                  View
                </Button>
              }
            />
          </Grid>
          <Grid xs={12} lg={6}>
            <ProductCard
              heading="DeBrief"
              content="DeBrief is a tool that helps you make legal arguments that are custom tailored for the judges handling your cases."
              action={
                <Button
                  component={Link}
                  to="/debrief"
                  color="secondary"
                  size="small"
                  variant="contained"
                >
                  View
                </Button>
              }
            />
          </Grid>
          <Grid xs={12} lg={6}>
            <ProductCard
              heading="Judges"
              content="Judges allows you to search for individual judges based on their philosophies of the law, political affiliations, and more."
              action={
                <Button
                  component={Link}
                  to="/judges"
                  color="secondary"
                  size="small"
                  variant="contained"
                >
                  View
                </Button>
              }
            />
          </Grid>
          {isCiceroEnabled && (
            <Grid xs={12} lg={6}>
              <ProductCard
                heading="Cicero"
                content="Cicero is a tool that helps you practice and refine your oral presentations."
                action={
                  <Button
                    component={Link}
                    to="/cicero"
                    size="small"
                    color="secondary"
                    variant="contained"
                  >
                    View
                  </Button>
                }
              />
            </Grid>
          )}
          {!md && (
            <Grid xs={12}>
              <Stack justifyContent="flex-end">
                <Button onClick={handleLogout}>Logout</Button>
              </Stack>
            </Grid>
          )}
        </Grid>

        {isUnsupportedBrowser() && (
          <Box
            sx={{
              mt: "auto",
              pt: 2,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Alert
              severity="warning"
              sx={{
                boxShadow: 3,
                maxWidth: "800px",
                width: "100%",
                borderRadius: 1,
              }}
            >
              It appears you are using an unsupported browser to use Rhetoric.
              Please note that some features or visuals may be affected. To
              ensure an optimal experience using our platform, please use
              Chrome, Safari, or Edge.
            </Alert>
          </Box>
        )}

        {!onboarding.isActive && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "auto",
              pt: 4,
              pb: 2,
            }}
          >
            <Button
              onClick={handleStartOnboarding}
              variant="text"
              sx={{
                color: "text.secondary",
                fontSize: "0.9rem",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
                padding: "8px 24px",
                borderRadius: 2,
              }}
            >
              View Onboarding
            </Button>
          </Box>
        )}
      </Box>
    </Default>
  );
};
