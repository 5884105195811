import React from "react";
import { Box, Paper, Stack, Button, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TransitionGroup } from "react-transition-group";
import { CiceroCard } from "../../components/CiceroCard";
import { NavLink } from "react-router-dom";

export const CiceroItems = ({ assignments }) => {
  return (
    <>
      <Stack>
        <Box
          backgroundColor="white"
          paddingY={4}
          paddingX={[0, 4]}
          position="sticky"
          top={0}
          zIndex={10}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  component={NavLink}
                  to="/cicero/create"
                  variant="contained"
                  color="primary"
                >
                  New Recording
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box backgroundColor="grey.100" paddingY={4} paddingX={[0, 4]}>
          <Grid container rowSpacing={4} xs={12} component={TransitionGroup}>
            {assignments.map((assignment, index) => (
              <Collapse
                key={assignment.activity_id || index.toString()}
                sx={{ width: "100%" }}
              >
                <Grid xs={12}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: (theme) => theme.palette.grey[100],
                      padding: 2,
                      borderRadius: 2,
                    }}
                  >
                    <CiceroCard
                      id={assignment.activity_id || index.toString()}
                      title={assignment.case_name || assignment.activity_title}
                      created_timestamp={assignment.created_timestamp}
                      activity_id={assignment.activity_id}
                      context_id={assignment.context_id}
                      case_name={assignment.case_name}
                      client_number={assignment.client_number}
                      matter_number={assignment.matter_number}
                      activity_type={assignment.activity_type}
                      activity_title={assignment.activity_title}
                    />
                  </Paper>
                </Grid>
              </Collapse>
            ))}
          </Grid>
        </Box>
      </Stack>
    </>
  );
};
